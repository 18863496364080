<template>
  <v-row justify="center">
    <v-col
      md="10"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <h1 class="text-center my-5">
          C.R.E.I.P.E.F.
        </h1>
        <h2 class="text-center my-5">
          Compte-Rendu d’Entretien Individuel Préalable à l’Entrée en Formation.
        </h2>
        <v-card>
          <v-card-title>
            <h4>
              Informations
            </h4>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                md="6"
                sm="12"
              >
                <v-text-field
                  v-model="creipef.nom"
                  label="Nom de famille"
                  :rules="[v => !!v || 'Le nom est requis']"
                  readonly
                  dense
                  placeholder="Nom de famille"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                sm="12"
              >
                <v-text-field
                  v-model="creipef.prenom"
                  label="Prénom"
                  :rules="[v => !!v || 'Le prénom est requis']"
                  readonly
                  dense
                  placeholder="Prénom"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                md="6"
                sm="12"
              >
                <v-text-field
                  v-model="creipef.tel"
                  label="Téléphone"
                  :rules="[v => !!v || 'Le téléphone est requis']"
                  readonly
                  dense
                  placeholder="Téléphone"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                sm="12"
              >
                <v-text-field
                  v-model="niceDate"
                  label="Date de l'entretien"
                  readonly
                  dense
                  placeholder="Date"
                  required
                ></v-text-field>
                <DateTimePickerVue
                  color="primary"
                  button-text="Changer la date"
                  :no-time="true"
                  @newDate="changeDateCreipef"
                ></DateTimePickerVue>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              1 – Quel est le projet professionnel de la personne reçue ?
            </h4>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="creipef.projetPro"
              :rules="[v => !!v || 'Ce champs est requis']"
              outlined
              label="Projet professionnel"
            ></v-textarea>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              2 – Quelles sont les actions de formation proposées par l’organisme de formation ?
            </h4>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="creipef.actionsFormation"
              :rules="[v => !!v || 'Ce champs est requis']"
              outlined
              label="Actions de formation proposées"
            ></v-textarea>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              3 – Quelles ont été les méthodes d’évaluation préalables ? (test, entretien…)
            </h4>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="creipef.methodesEval"
              :rules="[v => !!v || 'Ce champs est requis']"
              outlined
              label="Méthodes d’évaluation préalables"
            ></v-textarea>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              4 – Quels sont les points forts du salarié ?
            </h4>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="creipef.pointsFortsSalarie"
              :rules="[v => !!v || 'Ce champs est requis']"
              outlined
              label="Points fort salarié"
            ></v-textarea>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              5 – Quels sont les domaines sur lesquels le salarié devra plus particulièrement travailler ?
            </h4>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="creipef.domainesTravail"
              :rules="[v => !!v || 'Ce champs est requis']"
              outlined
              label="Domaines de travail"
            ></v-textarea>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
            <h4>
              6 – Une réduction du parcours est-elle envisagée ?
            </h4>
          </v-card-title>
          <v-card-text>
            <v-radio-group
              v-model="creipef.reductionParcours"
              row
            >
              <v-radio
                label="Oui"
                :value="true"
              ></v-radio>
              <v-radio
                label="Non"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-text v-if="creipef.reductionParcours">
            <v-textarea
              v-model="creipef.explicationReduction"
              outlined
              label="Si oui, pourquoi ... ?"
            ></v-textarea>
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-model="creipef.duree"
              label="Durée de la formation"
              readonly
              dense
              placeholder="Durée de la formation"
              required
            ></v-text-field>
          </v-card-text>
        </v-card>
        <v-btn
          block
          class="mt-3"
          @click="generatePdf"
        >
          Envoyer
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>

import axios from 'axios'
import {
  mdiStar, mdiStarOutline, mdiStarHalfFull,
} from '@mdi/js'
import DateTimePickerVue from '@/components/DateTimePicker.vue'

export default {
  components: {
    DateTimePickerVue,
  },
  data() {
    return {
      student: {},
      dialog: false,
      valid: true,
      icons: {
        mdiStar,
        mdiStarOutline,
        mdiStarHalfFull,
      },
      creipef: {
        date: new Date(),
        nom: '',
        prenom: '',
        tel: '',
        reductionParcours: false,
        duree: 0,
        niceDate: '',
      },
    }
  },
  computed: {
    niceDate() {
      return this.$date(new Date(this.creipef.date), 'dd MMMM yyyy')
    },
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios.get(`${process.env.VUE_APP_API_URL}/students/${this.$route.params.id}`)
        .then(stud => {
          this.student = stud.data
          this.creipef.nom = stud.data.nom
          this.creipef.prenom = stud.data.prenom
          this.creipef.tel = stud.data.tel
          this.creipef.duree = `${stud.data.formation.duree} heures`
        })
        .catch(error => console.error(error))
    },
    changeDateCreipef(e) {
      this.creipef.date = e
    },
    generatePdf() {
      if (this.$refs.form.validate()) {
        this.creipef.niceDate = this.$date(new Date(this.creipef.date), 'dd/MM/yyyy')
        axios.post(`${process.env.VUE_APP_API_URL}/students/pdf/creipef/${this.$route.params.id}`, this.creipef)
          .then(pdf => {
            // LINK TO INVOICE COLLECTION
            axios.post(`${process.env.VUE_APP_API_URL}/creipefs`, {
              student: this.student.id, pdf: pdf.data[0].id, createdBy: this.$store.state.user.id,
            })
              .then(() => {
                this.$store.commit('snackMe', {
                  color: 'success',
                  text: 'Compte rendu créé avec succès',
                  value: true,
                })
                this.$router.push({ name: 'student-single', params: { id: this.student.id } })
              })
              .catch(err => console.log(err))
          }).catch(error => {
            console.log(error)
            this.$store.commit('snackMe', {
              color: 'error',
              text: `Erreur lors de la génération du pdf ${error}`,
              value: true,
            })
          })
      }
    },
  },
}
</script>

<style>

</style>
